import React, { useState, useRef } from 'react';
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import ReactMarkdown from 'react-markdown';

export default function DemoChatPage() {
    const [messages, setMessages] = useState([
        { text: "Hi! I'm your TenXer assistant. How can I help you today?", author: 'ai', timestamp: new Date().toLocaleTimeString() }
    ]);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [inputMessage, setInputMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [expandedApiCalls, setExpandedApiCalls] = useState({});
    const [apiCalls, setApiCalls] = useState([]);
    const endOfMessagesRef = useRef(null);
    const inputRef = useRef(null);

    const toggleApiCall = (callIndex) => {
        setExpandedApiCalls(prev => ({
            ...prev,
            [callIndex]: !prev[callIndex]
        }));
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim()) {
            const newMessage = {
                text: inputMessage,
                author: 'user',
                timestamp: new Date().toLocaleTimeString()
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setInputMessage('');
            setIsProcessing(true);

            // Demo response logic
            setTimeout(() => {
                if (inputMessage.toLowerCase().includes('customers who made a purchase') && 
                    inputMessage.toLowerCase().includes('haven\'t returned')) {
                    // First API call - Get customers with recent purchases
                    const purchaseApiCall = {
                        name: "getCustomerPurchases",
                        url: "https://api.example.com/v1/customers/purchases",
                        request: {
                            startDate: "2024-02-18",
                            endDate: "2024-03-18",
                            limit: 1000,
                            offset: 0
                        },
                        response: {
                            status: 200,
                            data: {
                                customers: [
                                    {
                                        customerId: "CUST-89432",
                                        customerName: "Sarah Johnson",
                                        purchaseDate: "2024-03-15",
                                        purchaseAmount: 299.00,
                                        productId: "PLAN-PREMIUM",
                                        productName: "Premium Plan"
                                    },
                                    // ... more customers
                                ],
                                pagination: {
                                    total: 523,
                                    limit: 1000,
                                    offset: 0
                                }
                            }
                        }
                    };
                    setApiCalls(prev => [...prev, purchaseApiCall]);

                    // Second API call - Get last login dates for these customers
                    const activityApiCall = {
                        name: "getCustomerActivity",
                        url: "https://api.example.com/v1/customers/activity",
                        request: {
                            customerIds: ["CUST-89432", "CUST-89445"], // Would include all customer IDs from first call
                            activityTypes: ["login", "site_visit"],
                            startDate: "2024-02-18"
                        },
                        response: {
                            status: 200,
                            data: {
                                customerActivities: [
                                    {
                                        customerId: "CUST-89432",
                                        lastLoginDate: "2024-03-16",
                                        lastActivityDate: "2024-03-16",
                                        activityCount: 1
                                    },
                                    {
                                        customerId: "CUST-89445",
                                        lastLoginDate: "2024-03-12",
                                        lastActivityDate: "2024-03-12",
                                        activityCount: 1
                                    }
                                ]
                            }
                        }
                    };
                    setApiCalls(prev => [...prev, activityApiCall]);

                    const aiResponse = `I've analyzed recent customer activity and found these patterns:

**Overview:**
* 523 customers made purchases in the last 30 days
* 142 (27%) haven't returned since their purchase

**Top Recent Cases:**
1. Sarah Johnson
   * Purchased Premium Plan ($299) on March 15
   * Last active: March 16
   
2. Michael Chen
   * Purchased Enterprise Plan ($499) on March 12
   * Last active: March 12

**Recommended Actions:**
1. Send targeted re-engagement emails
2. Schedule follow-up calls for enterprise customers
3. Offer onboarding assistance

Would you like me to:
1. Generate a detailed risk assessment
2. Draft re-engagement email templates
3. Prioritize customers by purchase value`;
                    
                    setMessages(prevMessages => [...prevMessages, {
                        text: aiResponse,
                        author: 'ai',
                        timestamp: new Date().toLocaleTimeString()
                    }]);
                } else {
                    setMessages(prevMessages => [...prevMessages, {
                        text: "I can help you analyze customer engagement patterns. Try asking 'Show me all customers who made a purchase in the last 30 days but haven't returned to the site.'",
                        author: 'ai',
                        timestamp: new Date().toLocaleTimeString()
                    }]);
                }
                
                setIsProcessing(false);
                endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }
    };

    return (
        <div className="w-full h-full">
            <div className="flex h-full w-full bg-white rounded-lg overflow-hidden">
                <div className={`flex flex-col transition-all duration-300 ease-in-out ${
                    sidebarOpen ? 'w-[calc(100%-24rem)]' : 'w-full'
                }`}>
                    <div className="flex-1 overflow-y-auto p-4 space-y-4">
                        {messages.map((msg, index) => (
                            <div key={index} className={`flex ${msg.author === 'user' ? 'justify-end' : 'justify-start'}`}>
                                <div className={`max-w-2xl p-3 rounded-lg ${
                                    msg.author === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
                                }`}>
                                    <ReactMarkdown 
                                        className={`prose ${msg.author === 'user' ? 'prose-invert' : ''} max-w-none`}
                                        components={{
                                            // Customize paragraph spacing
                                            p: ({node, ...props}) => <p className="mb-2 last:mb-0" {...props} />,
                                            // Style lists
                                            ul: ({node, ...props}) => <ul className="list-disc ml-4 mb-2" {...props} />,
                                            ol: ({node, ...props}) => <ol className="list-decimal ml-4 mb-2" {...props} />,
                                            // Style code blocks
                                            code: ({node, ...props}) => (
                                                <code className="bg-gray-700 text-gray-100 px-1 rounded" {...props} />
                                            ),
                                        }}
                                    >
                                        {msg.text}
                                    </ReactMarkdown>
                                    <p className={`text-xs mt-1 ${msg.author === 'user' ? 'text-blue-200' : 'text-gray-500'}`}>
                                        {msg.timestamp}
                                    </p>
                                </div>
                            </div>
                        ))}
                        <div ref={endOfMessagesRef} />
                    </div>
                    <div className="p-2 border-t">
                        <div className="flex items-center space-x-3">
                            <Input
                                ref={inputRef}
                                type="text"
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                className="flex-1"
                                placeholder="Type your message..."
                            />
                            <Button
                                onClick={handleSendMessage}
                                disabled={isProcessing}
                                className="bg-blue-500 hover:bg-blue-600 text-white h-10"
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Sidebar */}
                <div className={`relative transition-all duration-300 ease-in-out ${
                    sidebarOpen ? 'w-96' : 'w-0'
                }`}>
                    {/* Toggle button */}
                    <button
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        className="absolute -left-8 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-l-md shadow-md focus:outline-none"
                    >
                        {sidebarOpen ? (
                            <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                        ) : (
                            <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                        )}
                    </button>

                    {/* Sidebar content */}
                    <div className="h-full bg-white border-l border-gray-200">
                        <div className="h-full p-6 overflow-y-auto">
                            <h2 className="text-lg font-semibold mb-4">API Calls</h2>
                            {apiCalls.length === 0 ? (
                                <p className="text-sm text-gray-600">No API calls made yet</p>
                            ) : (
                                <div className="space-y-6">
                                    {apiCalls.map((call, index) => (
                                        <div key={index} className="bg-gray-50 rounded-lg p-4">
                                            <div 
                                                className="flex items-center justify-between cursor-pointer"
                                                onClick={() => toggleApiCall(index)}
                                            >
                                                <span className="font-medium text-gray-700">
                                                    API Call #{index + 1}
                                                </span>
                                                {expandedApiCalls[index] ? (
                                                    <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                                                ) : (
                                                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                                )}
                                            </div>
                                            
                                            {expandedApiCalls[index] && (
                                                <div className="mt-2 space-y-2">
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Function:</h4>
                                                        <p className="text-sm text-gray-600">{call.name}</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">URL:</h4>
                                                        <p className="text-sm text-gray-600 break-all">{call.url}</p>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Request:</h4>
                                                        <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                            {JSON.stringify(call.request, null, 2)}
                                                        </pre>
                                                    </div>
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-700">Response:</h4>
                                                        <pre className="mt-1 p-2 bg-gray-800 text-white rounded text-sm overflow-x-auto">
                                                            {JSON.stringify(call.response, null, 2)}
                                                        </pre>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}