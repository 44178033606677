import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Button } from "./ui/button"

export default function LearnMoreSection() {
  return (
    <section className="bg-gray-50">
      <div className="container mx-auto px-4">
        {/* Hero Image Section */}
        <div className="max-w-7xl mx-auto mb-16">
          <img 
            src="/demo-image-tenxer.png" 
            alt="TenXer Product Demo" 
            className="w-full rounded-lg shadow-xl"
          />
        </div>

        {/* Main Description */}
        <div className="max-w-7xl mx-auto mb-4 py-2 md:py-6 lg:py-8 xl:py-10">
          <h2 className="text-4xl font-bold text-center mb-6">
            Your APIs. Now conversational.
          </h2>
          <div className="text-gray-600 text-lg leading-relaxed">
            <p className="mb-4">Imagine asking your data questions like:</p>
            <ul className="list-disc pl-4 md:pl-8 mb-6 space-y-2">
              <li className="ml-0 md:ml-2">"What are the latest customer signups from the EU?"</li>
              <li className="ml-0 md:ml-2">"Show me the top 10 performing products this month."</li>
              <li className="ml-0 md:ml-2">"Create a report of all users who haven't logged in for 30 days."</li>
            </ul>
            <p>
              With TenXer, you can simply register your APIs and start chatting with your data using natural language. No more tedious scripting or complex queries. TenXer handles the heavy lifting, so you can focus on what matters most.
            </p>
          </div>
        </div>
        
        {/* Cards Section */}
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-items-stretch">
            {/* Development Card */}
            <Card className="w-full">
              <CardHeader>
                <CardTitle>For Software Developers</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li><b>Ditch cURL and its limitations:</b> Interact with your APIs using natural language – no more crafting complex requests.</li>
                  <li><b>Test any API, anywhere:</b> Connect to localhost, production, or even APIs behind your VPN with ease.</li>
                  <li><b>Debug in real-time:</b> Inspect API responses and identify issues instantly, all within the chat interface.</li>
                  <li><b>Code less, do more:</b> Skip the scripting and get straight to the data you need with conversational commands.</li>
                </ul>
              </CardContent>
            </Card>

            {/* Product Card */}
            <Card className="w-full">
              <CardHeader>
                <CardTitle>For Product Managers</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li><b>Reduce reliance on data teams:</b> Answer data questions without needing to involve engineers or analysts.</li>
                  <li><b>Uncover hidden trends:</b> Identify customer behavior patterns and emerging trends by analyzing API data with natural language queries.</li>
                  <li><b>Make data-driven decisions faster:</b> Access and analyze key metrics in seconds to inform product strategy and roadmap prioritization.</li>
                  <li><b>Improve product iteration cycles:</b> Test hypotheses and get feedback on new features quickly by querying real user data in real-time.</li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  )
}